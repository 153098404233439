<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Liesbeth Brinkman</h2>
          <p>
            Mijn naam is Liesbeth Brinkman. In 1996 ben ik met veel plezier met
            mijn werk als fysiotherapeut gestart. In de jaren erna heb ik
            verscheidene opleidingen en bij- en nascholingen gevolgd, zoals een
            opleiding Manuele therapie, een opleiding Haptotherapie, een Master
            of Science-opleiding in de Manuele therapie en een Master of Science
            opleiding in de Osteopathie, naast korte bij -en
            nascholingscursussen.
          </p>
          <p>
            Ik heb hiervoor gekozen, omdat ik graag een positieve bijdrage lever
            aan het herstelproces van mensen en me daar verder in wilde
            ontwikkelen, zodat ik mensen steeds beter kan begeleiden in hun
            herstelproces. Hierbij ga ik uit van de holistische visie dat
            lichaam en geest met elkaar verbonden zijn.
          </p>
          <p>
            Mijn doel is om bij een behandeling verder te kijken dan alleen de
            klacht en dus ook naar de onderliggende oorzaak en de samenhang met
            andere aspecten van het lichaam, het dagelijks leven en de belasting
            en belastbaarheid van elk persoon.
          </p>
          <!-- <h3>Specialismen</h3>
          <p></p> -->
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>
              HBO - Opleiding Fysiotherapie, HR&O te Rotterdam afgestudeerd in
              1996
            </li>
            <li>
              HBO - Opleiding Manuele therapie, SOMT te Amersfoort, afgestudeerd
              in 2003
            </li>
            <li>
              HBO - Opleiding Haptotherapie, Academie voor Haptotherapie te
              Doorn van 2003 - 2006
            </li>
            <li>
              Master of Science - Opleiding Manuele Therapie, SOMT te
              Amersfoort, afgestudeerd in 2015
            </li>
            <li>
              Master of Science - Opleiding Osteopathie, IAO te Zeist,
              afgestudeerd in 2020.
            </li>
          </ul>

          <img src="../../assets/team/liesbeth.png" alt="Liesbeth" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
